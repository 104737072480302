<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Peringatan Obat'">
          <template v-slot:body>
            <div class="row align-items-center">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    image-container
                  "
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      src="/img/registrants/default.svg"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col" v-if="imgLoaded != false">
                <table class="table mb-3">
                  <tr>
                    <td><strong>Tanggal</strong></td>
                    <td>{{ data.created_at_display }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nama Klinik</strong></td>
                    <td>{{ data.tenant_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Paket</strong></td>
                    <td>
                      {{ data.subscription_package_name }} ({{
                        data.subscription_package_duration
                      }}
                      hari - Rp{{
                        data.subscription_package_price.toLocaleString(
                          "id-ID"
                        )
                      }})
                    </td>
                  </tr>
                  <tr>
                    <td><strong>status</strong></td>
                    <td>{{ data.workstate_name }}</td>
                  </tr>
                  <tr v-if="data.workstate_id == 14">
                    <td><strong>DIbayar pada</strong></td>
                    <td>{{ data.display_paid_at }}</td>
                  </tr>
                  <tr>
                    <td><strong>Metode Pembayaran</strong></td>
                    <td>{{ data.payment_method }} - {{data.payment_bank}}</td>
                  </tr>
                </table>
                <button
                  class="btn mx-1 btn-secondary"
                  @click="$router.push('/billings')"
                >
                  <i class="fas fa-fw fa-arrow-left"></i> Kembali
                </button>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Detail",

  components: {
    Card,
  },

  data() {
    return {
      imgLoaded: true,
      data: [],
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async get() {
      this.data = await module.get("manage-billings/" + this.$route.params.id);
      // this.getMedicineHistory(this.data.medicine_id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/manage-billings");
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Billing", route: "/billings/" },
      { title: "Detail" },
    ]);

    this.get();
  },
};
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>